body {
  overscroll-behavior-y: contain;
}


.App {
  background-color: white;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: row;
  margin: 10px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.navDiv {
  background-color: lightblue;
  padding: 15px;
  display: flex;
  flex-direction: column;
  width: 20vw;
  height: 95vh;
}

.viewArea {
  background-color: rgb(230, 230, 230);
  height: 70vh;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  overflow-x:hidden;
  overflow-y: scroll;
  position: relative;

}
.scrollEnd {
  background-color: transparent;
  font-size: 35px;
  width: fit-content;
  color: white;
  position: sticky;
  bottom: 2px;
  left: 50%;
  cursor: pointer;
}

.msgSystem {
  align-self: left;
  color: green;
  font-size: 90%;
  white-space: wrap;
  overflow-wrap: break-word;
  max-width: 75%;
  padding-top: 10px;
 
}

.msgBody {
  align-self: flex-start;
  background-color: rgb(0, 178, 255);
  color: white;
  white-space: wrap;
  overflow-wrap: break-word;
  max-width: 70%;
  padding: 10px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  margin: 10px;
 
}

.msgBody-self {
  align-self: flex-end;
  background-color: white;
  color: black;
  white-space: wrap;
  overflow-wrap: break-word;
  text-align: right;
  max-width: 70%;
  padding: 10px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  margin: 10px;
}


.msgBox {
  font-size: 15px;
  width: 75vw;
  height: 25px;
  overflow-x:visible;
  overflow-wrap: break-word;
  resize: none;
  margin-top: 5px;
  margin-top: 5px;
  margin-right: 5px;
  background-color: rgb(230, 230, 230);
  border-color: white;
  border-radius: 10px;
  padding: 5px;
  border-style: hidden;
}

.sendBtn {
  width: 14vw;
  height:7vh;
  border-radius: 5px;
  border-style: none;
  background-color: rgb(0, 178, 255);
  color: white;
}

.userBox {
  width:150px;
  height: 3vh;
  padding: 5px;
  background-color: rgb(230, 230, 230);
  border-style: hidden;
  border-radius: 5px;
}

.logInBtn {
  height:3vh;
  width: 55px;
  border-radius: 5px;
  border-style: none;
  background-color: rgb(56, 145, 78);
  color: white;
}

.status {
  font-style: italic;
  color: gray;
}

.userPanel {
  text-align: center;
  background-color: lightcoral;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 90vh;
  width: 200px;
  padding: 5px;
  border-bottom-left-radius: 5px;
  transition: 300ms;
  
}

.userPanel.min {
  width: 50px;
  height: 20px;
}

.hide {
  display:none;
}